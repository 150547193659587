import consumer from "./consumer"

consumer.subscriptions.create("OcrResultsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel

    function updateConfidenceClass(container, recognized_value, confidence, manual_value, low_confidence, is_confirmed) {
      container.classList.remove('is-manual-entry')
      container.classList.remove('is-automatic-entry')
      container.classList.remove('has-low-confidence')
      container.classList.remove('is-confirmed')

      container.setAttribute('title', '')

      if (manual_value && manual_value != '' && manual_value != recognized_value) {
        container.classList.add('is-manual-entry')
      } else {
        container.classList.add('is-automatic-entry')
        if (low_confidence) {
          container.classList.add('has-low-confidence')
        }
      }

      if (is_confirmed) {
        container.classList.add('is-confirmed')
      }
    }

    function updateValue(field) {
      var container = document.getElementById(`page_${data.page_id}_${field}`)
      var value = data[`${field}_l`]
      if (value === undefined) {
        value = data[field]
      }

      if (value !== undefined && container) {
        var old_value = container.textContent.trim()
        container.textContent = value
        updateConfidenceClass(container,
          data[`ocr_${field}`],
          data[`${field}_confidence`],
          data[`manual_${field}`],
          data[`${field}_low_confidence?`],
          data['confirmed_at'])

        // Animation on value change.
        if (old_value != value) {
          // console.log(`page = ${data.page_id}, field = ${field}: "${old_value}" => "${value}", queueing animation.`);
          anime({
            targets: `#page_${data.page_id}_${field}`,
            duration: 1000,
            color: '#adadad',
            direction: 'reverse',
            easing: "easeOutExpo",
            delay: 1000
          })
        }
      }

      var confidence_container = document.getElementById(`page_${data.page_id}_${field}_confidence`)
      var confidence = data[`${field}_confidence`]
      if (confidence !== undefined && confidence_container) {
        confidence_container.textContent = confidence
      }
    }

    const ocr_fields = ['barcode', 'customer_name', 'customer_number', 'license', 'vin', 'service_type', 'type', 'amg_customer', 'hub_cap', 'tire_pressure_control', 'finas_number', 'remark']
    const wheels = ['front_left', 'front_right', 'rear_left', 'rear_right']
    const generic_wheel_fields = ['make', 'model', 'season', 'dimensions', 'production_week', 'profile', 'rim_type', 'cover']
    const wheel_fields = []
    wheels.forEach(function (wheel) {
      generic_wheel_fields.forEach(function (field) {
        wheel_fields.push(`wheel_${wheel}_${field}`);
      });
    });

    ocr_fields.forEach(updateValue)
    wheel_fields.forEach(updateValue)
  }
});
