import consumer from "./consumer"

consumer.subscriptions.create("PagesChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    
    var table_body = document.getElementById(`scan_${data.scan_id}_pages_tbody`)
    var container
    
    // Do we have a table entry available?
    container = document.getElementById(`page_${data.id}`)
    
    // Remove the table row if the record has been destroyed.
    if (container && data._destroyed) {
      if (table_body) {
        container.parentNode.removeChild(container)
        
        // Hide the table if it does not have anything to show.
        var table = document.getElementById(`scan_${data.scan_id}_pages_table`)
        var none_message = document.getElementById('`scan_${data.scan_id}_no_pages_message`')
        
        if (table && none_message) {
          if (table.rows.length <= 1) { // 1 = header row
            table.classList.add('is-hidden')
            none_message.classList.remove('is-hidden')
          }
        }
      }
      
      return;
    }
    



    // Status
    container = document.getElementById(`page_${data.id}_status`)
    if (container) { container.setAttribute('data-status', data.status.replace(/_/g, '-')) }
    
    // Ignore
    container = document.getElementById(`page_${data.id}_ignore`)
    if (container) { container.setAttribute('data-ignore', data.ignore ? "true" : "false") }
    
    // Is Empty
    container = document.getElementById(`page_${data.id}_is_empty`)
    if (container) { container.setAttribute('data-is-empty', data.is_empty ? "true" : "false") }
    
    // Error Text
    container = document.getElementById(`page_${data.id}_status_error`)
    if (container) { container.setAttribute('title', data.error_text) }
  
    // Page Number
    container = document.getElementById(`page_${data.id}_page_number`)
    if (container) { container.textContent = data.page_number }

    // Created At
    container = document.getElementById(`page_${data.id}_created_at`)
    if (container) { container.textContent = data.created_at_l }
  
    // Updated At
    container = document.getElementById(`page_${data.id}_updated_at`)
    if (container) { container.textContent = data.updated_at_l }
  
    // Data Type
    container = document.getElementById(`page_${data.id}_data_type`)
    if (container) { container.textContent = data.data_type_l }
    
    // Processing Time
    container = document.getElementById(`page_${data.id}_processing_time`)
    if (container) { container.textContent = data.processing_time_l }
    
    // Preparation Time
    container = document.getElementById(`page_${data.id}_preparation_time`)
    if (container) { container.textContent = data.preparation_time_l }
    
    // OCR Time
    container = document.getElementById(`page_${data.id}_ocr_time`)
    if (container) { container.textContent = data.ocr_time_l }
    
    // Flag
    container = document.getElementById(`page_${data.id}_flag`)
    if (container) { container.setAttribute('data-flag', data.status.replace(/_/g, '-')) }
    
    // Comment
    container = document.getElementById(`page_${data.id}_comment`)
    if (container) { container.textContent = data.comment }
    
    // OCR Debug
    container = document.getElementById(`page_${data.id}_ocr_debug`)
    if (container) { container.setAttribute('data-ocr-debug', data.ocr_debug ? "true" : "false") }
  }
});
