import consumer from "./consumer"

consumer.subscriptions.create("ScansChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    
    var table_body = document.getElementById("scans_tbody")
    var container
    
    // Do we have a table entry available?
    container = document.getElementById(`scan_${data.id}`)
    
    // Remove the table row if the record has been destroyed.
    if (container && data._destroyed) {
      if (table_body) {
        container.parentNode.removeChild(container)
        
        // Hide the table if it does not have anything to show.
        var table = document.getElementById('scans_table')
        var none_message = document.getElementById('no_scans_message')
        
        if (table && none_message) {
          if (table.rows.length <= 1) { // 1 = header row
            table.classList.add('is-hidden')
            none_message.classList.remove('is-hidden')
          }
        }
      }
      
      return;
    }
    
    
    
    
    // Status
    container = document.getElementById(`scan_${data.id}_status`)
    if (container) { container.setAttribute('data-status', data.status.replace(/_/g, '-')) }
    
    // Error Text
    container = document.getElementById(`scan_${data.id}_status_error`)
    if (container) { container.setAttribute('title', data.error_text) }
  
    // Source
    container = document.getElementById(`scan_${data.id}_source`)
    if (container) { container.innerHTML = data.source }
    
    // Scanned At
    container = document.getElementById(`scan_${data.id}_scanned_at`)
    if (container) { container.textContent = data.scanned_at_l }
  
    // Created At
    container = document.getElementById(`scan_${data.id}_created_at`)
    if (container) { container.textContent = data.created_at_l }
  
    // Updated At
    container = document.getElementById(`scan_${data.id}_updated_at`)
    if (container) { container.textContent = data.updated_at_l }
  
    // Page Count
    container = document.getElementById(`scan_${data.id}_page_count`)
    if (container) { container.textContent = data.page_count }
  
    // Data Type
    container = document.getElementById(`scan_${data.id}_data_type`)
    if (container) { container.textContent = data.data_type_l }
  }
});
