import ClipboardJS from 'clipboard';
import flatpickr from "flatpickr";

window.flatpickr = flatpickr

const FlatpickrGerman = require("flatpickr/dist/l10n/de.js").default.de;
flatpickr.localize(FlatpickrGerman);

function getAll(selector) {
  return Array.prototype.slice.call(document.querySelectorAll(selector), 0);
}

function getWheelDataOcr(wheel, field) {
  let selector = `span[id*=_${wheel}_${field}]`
  let element = document.querySelector(selector)
  return element && element.textContent != '' ? element.textContent : null
}

function getWheelDataManual(wheel, field) {
  let id = `ocr_result_manual_wheel_${wheel}_${field}`
  let element = document.getElementById(id)

  if (element instanceof HTMLSelectElement) {
    return element.selectedIndex
  } else if (element && element.value != '') {
    return element.value
  } else {
    return null
  }
}

function setWheelDataManual(wheel, field, value) {
  let id = `ocr_result_manual_wheel_${wheel}_${field}`
  let element = document.getElementById(id)
  if (element) {
    if (element instanceof HTMLSelectElement) {
      element.selectedIndex = value
      console.debug(`Field ${id}: Selected index ${value}`)
    } else {
      element.value = value
      console.debug(`Field ${id}: Set value to '${value}'`)
    }
  }
}

function setupBehaviours() {
  // Clipboard.

  new ClipboardJS('.clipboard');
  new ClipboardJS('.clipboard-previous-sibling', {
    text: function (trigger) {
      if (trigger.previousSibling) {
        return trigger.previousSibling.textContent.trim();
      } else {
        return '';
      }
    }
  });

  // Wheel Data Transfers.

  var $wheel_transfers = getAll('.wheel-data-transfer');
  if ($wheel_transfers.length > 0) {
    $wheel_transfers.forEach(function ($el) {
      $el.addEventListener('click', function (event) {
        let source = $el.dataset.source.replace('-', '_')
        let target = $el.dataset.target.replace('-', '_')
        let selected_fields = $el.dataset.fields ? $el.dataset.fields.replace('-', '_').split(',') : []
        let ignored_fields = $el.dataset.ignoreFields ? $el.dataset.ignoreFields.replace('-', '_').split(',') : []
        let source_wheel = source
        let target_wheels = ['front_left', 'front_right', 'rear_left', 'rear_right'].filter(w => w != source && (w == target || target == 'all'))

        let base_id = `ocr_result_manual_wheel_${source_wheel}_`
        let fields = Array.prototype.slice.call(document.querySelectorAll(`*[id^="${base_id}"]`))
          .map(manual_source => manual_source.id.replace(base_id, ''))
          .filter(field => ignored_fields.indexOf(field) < 0)

        if (selected_fields.length > 0) {
          fields = fields.filter(field => selected_fields.indexOf(field) >= 0)
        }

        console.debug(`Fields: ${fields}`)

        fields.forEach(function (field) {
          let ocr_value = getWheelDataOcr(source_wheel, field)
          let manual_value = getWheelDataManual(source_wheel, field)
          let source_value = manual_value != null ? manual_value : ocr_value

          target_wheels.forEach(target_wheel => {
            let target_ocr_value = getWheelDataOcr(target_wheel, field)
            let target_manual_value = getWheelDataManual(target_wheel, field)

            if (target_manual_value != null && target_manual_value != source_value) {
              setWheelDataManual(target_wheel, field, source_value)
            } else if (target_ocr_value != null && target_ocr_value != source_value) {
              setWheelDataManual(target_wheel, field, source_value)
            } else if (target_ocr_value == null) {
              setWheelDataManual(target_wheel, field, source_value)
            }
          })
        });

        return false;
      });
    });
  }

  // Wheel Data Clearing.

  var $wheel_data_clears = getAll('.wheel-clear-data');
  if ($wheel_data_clears.length > 0) {
    $wheel_data_clears.forEach(function ($el) {
      $el.addEventListener('click', function (event) {
        let targets = $el.dataset.targets ? $el.dataset.targets.replace('-', '_').split(',') : []
        let base_id = `ocr_result_manual_`

        targets.forEach(function (target) {
          let id = `${base_id}${target}`
          console.debug(`Field ${id}: Clearing`)
          let element = document.getElementById(id)
          if (element) {
            if (element instanceof HTMLSelectElement) {
              element.selectedIndex = 0
              console.debug(`Field ${id}: Selected index 0`)
            } else {
              element.value = ''
              console.debug(`Field ${id}: Set value to ''`)
            }
          }
        });

        return false;
      });
    });
  }



  // Auto-focus input fields that require manual entries.
  var $manual_entry_required = getAll('.requires-manual-entry');
  if ($manual_entry_required.length > 0) {
    $manual_entry_required[0].focus();
  }



  // Hamburger Toggling.

  var $burgers = getAll('.burger');

  if ($burgers.length > 0) {
    $burgers.forEach(function ($el) {
      $el.addEventListener('click', function () {
        var target = $el.dataset.target;
        var $target = document.getElementById(target);
        $el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
      });
    });
  }



  // Modal Dialogs.

  var rootEl = document.documentElement;
  var $modals = getAll('.modal');
  var $modalButtons = getAll('.modal-button');
  var $modalCloses = getAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button');

  if ($modalButtons.length > 0) {
    $modalButtons.forEach(function ($el) {
      $el.addEventListener('click', function () {
        var target = $el.dataset.target;
        openModal(target);
      });
    });
  }

  if ($modalCloses.length > 0) {
    $modalCloses.forEach(function ($el) {
      $el.addEventListener('click', function () {
        closeModals();
      });
    });
  }

  function openModal(target) {
    var $target = document.getElementById(target);
    rootEl.classList.add('is-clipped');
    $target.classList.add('is-active');
  }

  function closeModals() {
    rootEl.classList.remove('is-clipped');
    $modals.forEach(function ($el) {
      $el.classList.remove('is-active');
    });
  }

  document.addEventListener('keydown', function (event) {
    var e = event || window.event;
    if (e.keyCode === 27) { // ESC
      closeModals();
      // closeDropdowns();
    }
  });



  // File Fields (show the current file name)
  var $files = getAll('.file.has-name')
  if ($files.length > 0) {
    $files.forEach(function ($el) {
      var fileEl = $el.querySelector('.file-input')
      if (!fileEl) return;

      fileEl.addEventListener('change', function () {
        var nameEl = $el.querySelector('.file-name')
        if (!nameEl) return;

        if (fileEl.files.count <= 0) {
          nameEl.innerHTML = ''
        } else {
          nameEl.innerHTML = fileEl.files[0].name;
        }
      });
    });
  }
}

// document.addEventListener("DOMContentLoaded", setupBehaviours);
document.addEventListener("turbolinks:load", setupBehaviours);
