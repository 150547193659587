// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("./channels")
require('clipboard')

import("flatpickr")
import("@rails/actiontext")

require("./ui")

import anime from 'animejs/lib/anime.es.js';
window.anime = anime;

require("@fortawesome/fontawesome-pro/js/fontawesome.js")
require("@fortawesome/fontawesome-pro/js/regular.js")
require("@fortawesome/fontawesome-pro/js/light.js")
require("@fortawesome/fontawesome-pro/js/solid.js")
FontAwesome.config.mutateApproach = 'sync'

import("chartkick")
import("chart.js")

import Drift from 'drift-zoom';
window.Drift = Drift;

window.addEventListener("turbolinks:load", function() {
  let images_to_zoom = document.querySelectorAll(".drift-zoom")

  if (images_to_zoom) {
    for (let image of images_to_zoom) {
      new window.Drift(image, {
        showWhitespaceAtEdges: true,
        inlinePane: true,
        inlineOffsetX: 0,
        inlineOffsetY: -120
      });
    }
  }
});